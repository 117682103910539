// @flow
import { type JSSThemeDefaultFormType } from '../JSSThemeDefaultForm';

export const JSSThemeForm60064: JSSThemeDefaultFormType = (themeVars) => {
  const {
    colors: {
      colorCombinations: { text2Text3 },
      colorTypes: {
        text2,
        neutral2,
        white,
        neutral4,
        background2,
        background5,
        background7,
        text3,
      },
    },
    borderRadius,
  } = themeVars;
  return {
    Input: {
      root: {
        borderRadius: borderRadius.sm,
        borderColor: neutral2,
        ...text2Text3,
        '@media (pointer: coarse)': {
          background: background5,
          '&::placeholder': {
            color: background2,
          },
        },

        '&::placeholder': {
          color: background7,
        },
        '&:-webkit-autofill': {
          '-webkit-box-shadow': `0 0 0 30px ${text3} inset !important`,
          '-webkit-text-fill-color': `${text2} !important`,
        },
      },
      betslipInput: {
        border: 'none',
        borderRadius: '2px',
        background: neutral4,
        '&:-webkit-autofill': {
          '-webkit-box-shadow': `0 0 0 30px ${neutral4} inset !important`,
          borderColor: `${neutral4} !important`,
          '-webkit-text-fill-color': `#1a1a1a !important`,
        },
      },
    },
    PasswordField: {
      iconShowPassword: {
        color: white,
      },
    },
  };
};
