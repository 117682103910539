// @flow
import { type JSSThemeDefaultSearchType } from '../JSSThemeDefaultSearch';

export const JSSThemeSearch60064: JSSThemeDefaultSearchType = (themeVars) => {
  const {
    margins,
    colors: {
      colorTypes: { white, text3, background5 },
    },
  } = themeVars;
  return {
    SearchBar: {
      searchContainer: {
        background: text3,
      },
      resultContainer: {
        background: text3,
        color: white,
      },
      inputContainer: {
        padding: [0, margins.md, margins.md],
      },
      input: {
        background: background5,
        color: white,
        borderRadius: [margins.xl, 0, 0, margins.xl],
      },
      iconContainer: {
        background: background5,
        borderRadius: [0, margins.xl, margins.xl, 0],
      },
    },
    SportsbookSearchResult: {
      title: {
        color: white,
        '&.mobile': {
          color: white,
        },
      },
      description: {
        color: text3,
        '&:hover': {
          color: text3,
          opacity: 0.5,
        },
      },
    },
  };
};
