// @flow
import { type JSSThemeDefaultLiveType } from '../JSSThemeDefaultLive';

export const JSSThemeLive60064: JSSThemeDefaultLiveType = (themeVars) => {
  return {
    LiveMenuEvent: {
      LiveEventRankedDesktop: {
        background: 'white !important',
        color: 'black !important',
      },
      TeamsText: {
        color: 'black !important',
        '@media (pointer: coarse)': {
          color: 'white !important',
        },
      },
    },
  };
};
