// @flow
import { type JSSThemeDefaultHeaderType } from '../JSSThemeDefaultHeader';

export const JSSThemeHeader60064: JSSThemeDefaultHeaderType = (themeVars) => {
  const {
    margins,
    colors: {
      colorTypes: { background4 },
    },
  } = themeVars;
  return {
    HeaderDesktop: {
      root: {
        background: background4,
      },
      authContentWrap: {
        marginRight: margins.md,
      },
    },
    LogoBrand: {
      logo: {
        maxWidth: '200px',
        marginLeft: margins.md,
        '@media screen and (max-width: 1200px)': {
          width: '90%',
        },
      },
      logoWrapper: {
        '@media screen and (max-width: 1100px)': {
          width: 190,
        },
      },
    },
  };
};
