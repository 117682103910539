// @flow
import { type JSSThemeDefaultBannerItemsType } from '../JSSThemeDefaultBannerItems';

export const JSSThemeBannerItems60064: JSSThemeDefaultBannerItemsType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { white, text2, border1, background5 },
    },
    fontSizes,
    margins,
  } = themeVars;
  return {
    BannerContent: {
      bannerContentRoot: {
        color: white,
        padding: margins.xm,
        justifyContent: 'space-between',
      },
      bannerContentFill: {
        padding: '0',
      },
      bannerContentCasinoCarousel: {
        maxWidth: 500,
      },
      bannerContentSportsbookCarousel: {
        padding: margins.xm,
        justifyContent: 'space-between',
      },
      bannerContentSportsbookSide: {
        padding: margins.lg,
        justifyContent: 'flex-start',
      },
      headerSportsbookSide: {
        paddingTop: margins.md,
      },
      bannerContentCasinoSide: {
        padding: [margins.xl, margins.lg],
      },
      bannerContentPromo: {
        padding: [margins.md],
      },
      headerSportsbookCarousel: {
        fontSize: [fontSizes.xl],
        fontWeight: 'normal',
        textShadow: 'none',
      },
      headerPromo: {
        fontSize: '26px',
        lineHeight: '1.2',
        fontWeight: 'bold',
        textShadow: '3px 3px 10px #000',
        color: '#d0d0d0',
      },
      descriptionSportsbookCarousel: {
        fontSize: fontSizes.sm,
        textShadow: 'none',
        color: white,
      },
      descriptionMobile: {
        color: white,
      },
      descriptionCasinoCarousel: {
        fontSize: [fontSizes.lg],
        color: white,
        padding: [margins.xs, 0],
      },
      descriptionSportsbookSide: {
        fontSize: fontSizes.lg,
        padding: [margins.xs, 0],
      },
      descriptionCasinoSide: {
        fontSize: fontSizes.lg,
        padding: [margins.xs, 0],
      },
      descriptionPromo: {
        fontSize: fontSizes.lg,
        lineHeight: '20px',
        padding: '0',
        color: '#d0d0d0',
      },
      textWrapperFill: {
        padding: '0',
        color: white,
        textShadow: '3px 3px 10px #000',
      },
      buttonsWrapperSportsbookCarousel: {
        margin: [margins.md, 0, 0, 0],
      },
      buttonsWrapperSportsbookSide: {
        margin: [margins.lg, 0],
      },
      buttonsWrapperCasinoSide: {
        margin: [margins.lg, 0],
      },
      buttonsWrapperPromo: {
        margin: [margins.lg, 0, 0, 0],
      },
    },
    BannerItemSportsbookCarouselMobile: {
      imgContainer: {
        minHeight: 100,
        height: 140,
        maxHeight: 140,
        alignItems: 'left',
        justifyContent: 'center',
      },
      description: {
        textAlign: 'left',
        lineHeight: 'normal',
        fontSize: fontSizes.md,
      },
      header: {
        fontSize: fontSizes.xxl,
        lineHeight: 'normal',
      },
    },
    BannerItemSportsbookCarousel: {
      bannerItemSportsbookCarouselRoot: {
        padding: [0, 0, margins.xm],
        margin: [margins.lg, margins.xs, 0],
        marginRight: 5,
        background: background5,
        borderRadius: 10,
      },
      heightFix: {
        minHeight: '258px',
      },
      imgContainer: {
        height: '100%',
        backgroundColor: 'transparent',
        backgroundPosition: 'center right',
      },
      contentWrapper: {
        width: '100%',
        minWidth: '250px',
        height: '100%',
        padding: '0',
        backgroundColor: 'none',
      },
    },
    CarouselSlidePrematch: {
      carouselSlidePrematchRoot: {
        background: background5,
      },
      descriptionTimeWrapper: {
        color: text2,
      },
      linkWrapper: {
        margin: [margins.md, 0],
      },
      linkEvent: {
        color: border1,
      },
    },
    SideBanner: {
      containerWithContent: {
        backgroundPosition: 'left center',
        padding: '0',
        display: 'flex',
      },
    },
    BannerItemCasinoCarousel: {
      mobile: {
        height: '200px',
        '&:after': {
          background: `linear-gradient(
            to right,
            rgba(10, 64, 50, 1) 0%,
            rgba(10, 64, 50, 0) 10%,
            rgba(10, 64, 50, 0) 100%
          )`,
        },
      },
    },
  };
};
