// @flow
import { LoadableComponentWrapper } from '../../components/components/LoadableComponentWrapper';
import { createKoltronApp } from '../createKoltronApp';
import {
  JSSTheme60064,
  JSSThemeVariables60064,
  getJSSThemePatterns60064,
} from './JSSTheme60064';
import { GeoblockPage60064 } from './GeoblockPage60064';
// $FlowIgnore
import '../../../styles/application-koltron.scss';

createKoltronApp({
  DesktopComponent: LoadableComponentWrapper({
    loader: async () => (await import('./FullDesktop60064')).FullDesktop60064,
  }),
  MobileComponent: LoadableComponentWrapper({
    loader: () =>
      import('./FullMobile60064').then((module) => module.FullMobile60064),
  }),
  JSSTheme: JSSTheme60064,
  JSSThemeVariables: JSSThemeVariables60064,
  JSSThemePatterns: getJSSThemePatterns60064,
  GeoblockPage: GeoblockPage60064,
});
