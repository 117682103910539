// @flow
import {
  type JSSThemeShapeType,
  type JSSThemeVariablesShapeType,
  getColorCombinations,
  type ColorTypes,
  type GetJSSThemePatternsType,
  colorTypes,
} from '../JSSThemeDefault';
import { applyOnlyDesktop } from '../jss-helpers';
import { JSSThemeBetslip as betslip } from './JSSThemeBetslip60064';
import { JSSThemeMyBets60064 } from './JSSThemeMyBets60064';
import { JSSThemeSportsMenu60064 } from './JSSThemeSportsMenu60064';
import { JSSThemeForm60064 } from './JSSThemeForm60064';
import { JSSThemeBannerItems60064 } from './JSSThemeBannerItems60064';
import { JSSThemeHeaderMobile60064 } from './JSSThemeHeaderMobile60064';
import { JSSThemeOutcomes60064 } from './JSSThemeOutcomes60064';
import { JSSThemeHeaderMenu60064 } from './JSSThemeHeaderMenu60064';
import { JSSThemeHeader60064 } from './JSSThemeHeader60064';
import { JSSThemeBalance60064 } from './JSSThemeBalance60064';
import { JSSThemeBonuses60064 } from './JSSThemeBonuses60064';
import { JSSThemeCoupons60064 } from './JSSThemeCoupons60064';
import { JSSThemeAuthForm60064 } from './JSSThemeAuthForm60064';
import { JSSThemePopularLeagues60064 } from './JSSThemePopularLeagues60064';
import { JSSTheme60064Resets } from './JSSTheme60064Resets';
import { JSSThemeCasino60064 } from './JSSThemeCasino60064';
import { JSSThemeTooltip60064 } from './JSSThemeTooltip60064';
import { JSSThemeSearch60064 } from './JSSThemeSearch60064';
import { JSSThemeAccountSection60064 } from './JSSThemeAccountSection60064';
import { JSSThemeFooter60064 } from './JSSThemeFooter60064';
import { JSSThemeLive60064 } from './JSSThemeLive60064';
import { JSSThemeOryx60064 } from './JSSThemeOryx60064';

export const colorTypes60064: ColorTypes = {
  ...colorTypes,
  primary: '#ff3939',
  text1: '#fff',
  text2: '#fff',
  text3: '#333333',
  text4: '#333333',
  text5: '#eaeaea',
  background1: '#ff3939',
  background2: '#a2a2a2',
  background3: '#d12626',
  background4: '#333333',
  background5: '#484848',
  background6: '#484848', // new color
  background7: '#d1d1d1',
  shadow: 'rgba(0, 0, 0, 0.4)',
  accent: '#ff3939',
  success: '#54f763',
  error: '#ff3939',
  error2: '#d12626',
  error3: '#d12626',
  warning: 'orange',
  inform: '#68c124',
  neutral1: '#333333',
  neutral2: '#333333',
  neutral3: '#ff3939',
  neutral4: '#e6e0e0',
  border1: '#fff',
  border2: '#fefefe',
};

const pick = ({
  text3Border2,
  whitePrimary,
  text2Primary,
  whiteBg2,
  whiteBg4,
  whiteBg1,
  whiteText3,
  whiteNeutral2,
  whiteNeutral1,
  text2Neutral2,
  whiteSuccess,
  whiteAccent,
  whiteInform,
  whiteBorder1,
  whiteBorder2,
  bg4Border2,
  text3Bg4,
  text4Bg4,
  text2White,
  whiteNeutral3,
  text4Bg2,
  text2Text3,
  text3White,
  whiteBg3,
}) => ({
  text3Border2,
  whitePrimary,
  text2Primary,
  whiteBg2,
  whiteBg4,
  whiteBg1,
  whiteText3,
  whiteNeutral1,
  whiteNeutral2,
  text2Neutral2,
  whiteSuccess,
  whiteAccent,
  whiteInform,
  whiteBorder1,
  whiteBorder2,
  bg4Border2,
  text3Bg4,
  text4Bg4,
  text2White,
  whiteNeutral3,
  text4Bg2,
  text2Text3,
  text3White,
  whiteBg3,
});

export const colorCombinations60064 = getColorCombinations(
  colorTypes60064,
  pick
);

export const JSSThemeVariables60064: JSSThemeVariablesShapeType = {
  fonts: {
    default: {
      name: 'osg-open',
      fontFamily: 'osg-open, Helvetica Neue, Helvetica, Arial, sans-serif',
      config: [
        {
          path: 'Open/opensans-regular-webfont',
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
        {
          path: 'Open/opensans-bold-webfont',
          fontWeight: 'bold',
          fontStyle: 'normal',
        },
      ],
    },
  },
  colors: {
    colorTypes: colorTypes60064,
    colorCombinations: colorCombinations60064,
  },
};

export const JSSTheme60064: JSSThemeShapeType = (themeVars) => {
  const {
    colors: {
      colorCombinations: {
        whiteBg2,
        whiteBorder1,
        whitePrimary,
        text3White,
        whiteBg3,
      },
      colorTypes: {
        primary,
        white,
        neutral1,
        background4,
        text5,
        text3,
        background3,
        background5,
      },
    },
    fontSizes,
    margins,
  } = themeVars;
  return {
    Heading: {
      common: {
        background: 'transparent',
        color: white,
        fontSize: fontSizes.xxxl,
        letterSpacing: 'normal',
        padding: [margins.xs, margins.md, 0],
      },
    },
    mybets: JSSThemeMyBets60064(themeVars),
    Button: {
      root: {
        borderRadius: '2px',
        lineHeight: '32px',
        padding: '0 10px',
        fontWeight: 'bold',
        fontSize: 12,
        ...whiteBg2,
        ...applyOnlyDesktop({
          '&:hover': whiteBorder1,
        }),
      },
      block: {
        width: '100%',
      },
      primary: {
        ...whitePrimary,
        boxShadow: '0 5px 15px #ff3939bf',
        ...applyOnlyDesktop({
          '&:hover': {
            ...whiteBg3,
          },
        }),
      },
      linkSecondary: {
        '&:hover': {
          background: 'transparent',
        },
      },
      secondary: {
        background: 'transparent',
        color: white,
        '&:hover': {
          background: 'transparent',
          color: primary,
        },
      },
      accent: {
        background: primary,
        transition: '.3s ease-in',
        boxShadow: '0 5px 15px #ff3939bf',
        '&:hover': {
          background: background3,
          boxShadow: '0 5px 15px #ff3939bf',
        },
      },
      large: {
        lineHeight: '42px',
        fontSize: fontSizes.xl,
      },
      small: {
        padding: [margins.xs, margins.md],
      },
      linkAccent: {
        color: primary,
        ...applyOnlyDesktop({
          '&:hover': {
            color: primary,
            background: 'transparent',
          },
        }),
      },
      default: {
        ...text3White,
        '&:hover': {
          color: text3,
          background: text5,
        },
      },
    },
    betslip: betslip(themeVars),
    NoBetsMessage: {
      root: {
        background: background5,
        color: white,
        fontWeight: 'normal',
        fontSize: fontSizes.sm,
      },
    },
    Geoblock: {
      logoGeoblock: {
        background: text3,
      },
      rootGeoblock: {
        background: background5,
      },
    },
    sportsMenu: JSSThemeSportsMenu60064(themeVars),
    form: JSSThemeForm60064(themeVars),
    headerMobile: JSSThemeHeaderMobile60064(themeVars),
    banners: JSSThemeBannerItems60064(themeVars),
    headerMenu: JSSThemeHeaderMenu60064(themeVars),
    outcomes: JSSThemeOutcomes60064(themeVars),
    header: JSSThemeHeader60064(themeVars),
    authForm: JSSThemeAuthForm60064(themeVars),
    balance: JSSThemeBalance60064(themeVars),
    bonuses: JSSThemeBonuses60064(themeVars),
    coupons: JSSThemeCoupons60064(themeVars),
    popularLeagues: JSSThemePopularLeagues60064(themeVars),
    casino: JSSThemeCasino60064(themeVars),
    search: JSSThemeSearch60064(themeVars),
    footer: JSSThemeFooter60064(themeVars),
    tooltip: JSSThemeTooltip60064(themeVars),
    live: JSSThemeLive60064(themeVars),
    accountSection: JSSThemeAccountSection60064(themeVars),
    oryx: JSSThemeOryx60064(themeVars),
    LiveCalendar: {
      common: {
        margin: 0,
      },
      dateTitle: {
        color: '#000',
      },
      link: {
        color: background3,
      },
      dateEventNoMarkets: {
        '&:hover': {
          background: '#fff',
          color: '#000',
        },
      },
      dateEvent: {
        color: '#000',
        '&:hover': {
          background: '#eee',
        },
      },
    },
    BetslipMyBetsPanelTitle: {
      heading: {
        boxShadow: `none`,
        background: 'transparent',
        color: white,
      },
    },
    BetslipMyBetsStakePotentialTotalUI: {
      stakePotentialWrapper: {
        color: white,
        borderTop: `1px solid ${neutral1}`,
      },
    },
    BetslipMyBetsPanelUI: {
      panel: {
        background: background4,
        borderRadius: '2px',
      },
    },
    resets: JSSTheme60064Resets(themeVars),
    OfflineMessage: {
      popup: {
        color: text3,
      },
    },
  };
};

export const getJSSThemePatterns60064: GetJSSThemePatternsType = (
  themeVars
) => {
  const {
    colors: {
      colorCombinations: {
        whiteNeutral3,
        whitePrimary,
        whiteAccent,
        text2Neutral2,
        whiteBg4,
        whiteBg1,
      },
    },
    fontSizes,
    margins,
  } = themeVars;
  return {
    buttons: {
      primary: {
        ...whitePrimary,
        ...applyOnlyDesktop({
          '&:hover': whiteNeutral3,
        }),
      },
      secondary: {
        ...text2Neutral2,
        ...applyOnlyDesktop({
          '&:hover': whiteBg4,
        }),
      },
      accent: {
        ...whiteAccent,
        ...applyOnlyDesktop({
          '&:hover': whiteBg1,
        }),
      },
      large: {
        lineHeight: '42px',
        fontSize: fontSizes.xl,
      },
      small: {
        padding: [margins.xs, margins.md],
      },
    },
  };
};
