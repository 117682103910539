// @flow
import { type JSSThemeDefaultCasinoType } from '../JSSThemeDefaultCasino';
import { applyOnlyMobile, applyOnlyDesktop } from '../jss-helpers';

export const JSSThemeCasino60064: JSSThemeDefaultCasinoType = (themeVars) => {
  const {
    margins,
    fontSizes,
    colors: {
      colorTypes: { white, neutral1, text5, background3, text4, text3, accent },
    },
  } = themeVars;
  return {
    CasinoGameCategories: {
      gameCategories: {
        background: text3,
        marginTop: margins.xs,
        position: 'static',
        overflowX: 'auto',
        height: 'auto',
      },
      menuItem: {
        padding: '6px 14px',
        color: white,
        borderRight: 'none',
        fontWeight: 'bold',
        transition: '.3s ease-in',
        '&.active, &:hover': {
          color: accent,
          background: 'transparent',
        },
        ...applyOnlyDesktop({
          '&:hover': {
            color: accent,
            background: 'transparent',
          },
        }),
        ...applyOnlyMobile({
          '&:hover, &:active': {
            color: accent,
          },
        }),
      },
      iconChevron: {
        '&.active': {
          transform: 'rotateZ(90deg) translateX(1px) translateY(-2px)',
        },
      },
    },
    SearchBox: {
      inputWrap: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
      },
      inputSearch: {
        height: 32,
        border: '1px solid rgba(250,250,250,0.3)',
        borderRadius: 4,
        '&::placeholder': {
          opacity: 0.5,
        },
      },
      searchResult: {
        background: neutral1,
        padding: [0, margins.md],
      },
    },
    CasinoContainer: {
      providersFiltersWrap: {
        background: neutral1,
      },
    },
    CasinoGameLink: {
      rootReal: {
        fontWeight: 'bold',
        fontSize: fontSizes.md,
        boxShadow: '0 5px 15px #ff3939bf',
        '&:hover': {
          background: background3,
        },
      },
      rootFun: {
        fontSize: fontSizes.md,
        fontWeight: 'bold',
        background: white,
        color: text4,
        '&:hover': {
          background: text5,
          color: text4,
        },
      },
    },
  };
};
